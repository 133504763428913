<template>
  <div>
    <b-row class="d-flex justify-content-between">
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1 style="font-size: 2rem" class="text-colorBlue font-weight-bolder">
            {{ stats.total_subjects }}
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Subjects
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/dashboard/enroll.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1
            style="font-size: 2rem"
            class="text-colorOrange font-weight-bolder"
          >
            {{ stats.total_assignments }}
          </h1>
          <div>
            <h4
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Assignments
            </h4>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/dashboard/instructor.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      <b-col
        md=""
        class="d-flex align-items-center bg-colorWhite w-100 py-2 px-2 ml-0 ml-md-1 mb-1 mb-sm-0"
        style="border-radius: 20px"
      >
        <div class="w-50">
          <h1
            style="font-size: 2rem"
            class="text-colorYellow font-weight-bolder"
          >
            {{ stats.total_teachers }}
          </h1>

          <div>
            <h5
              class="text-colorBlack font-weight-bolder"
              style="font-size: 0.9rem"
            >
              Teachers
            </h5>
          </div>
        </div>
        <div class="text-right w-50">
          <img
            src="@/assets/images/icons/dashboard/class.svg"
            width="50px"
            img-fluid
          />
        </div>
      </b-col>
      
    </b-row>
    <b-row class="d-flex justify-content-between mt-2 h-50">
      <b-col
        md="6"
        class="p-0 m-0 bg-colorWhite w-100"
        style="border-radius: 20px"
      >
        <b-row>
          <b-col md="">
            <div class="pt-2 pl-2 d-flex w-100">
              <div class="w-50">
                <h3 class="font-weight-bolder text-colorBlack">Subjects</h3>
                <p class="font-weight-bold text-colorGray mb-50">
                  Your subjects are display here
                </p>
              </div>
              <div class="w-50 text-right pr-2">
                <p class="font-weight-bolder text-colorBlue">
                  <b-link :to="{ name: 'Subject' }"> View All</b-link>
                </p>
              </div>
            </div>
            <div class="d-flex w-100" v-for="sub in subjects" :key="sub.id">
              <b-card border-variant="light" class="mx-2 mb-50 w-100">
                <div class="w-100 d-flex align-items-end">
                  <div class="w-50">
                    <b-card-title class="text-colorBlack font-weight-bolder">
                      {{ sub.name }}
                    </b-card-title>
                    <b-card-text class="font-weight-bold text-colorGray">
                      {{ sub.code }} <br />
                      by {{ sub.teacher_data.name }}
                    </b-card-text>
                  </div>
                  <div class="w-50 text-right">
                    <b-link
                    :to="{ 
                      name: 'Lecture', 
                      query: { id: sub.id } 
                    }"
                    >
                      Load Lectures &nbsp;
                      <img
                        src="@/assets/images/icons/dashboard/greater.svg"
                        img-fluid
                    /></b-link>
                  </div>
                </div>
              </b-card>
            </div>
            
          </b-col>
        </b-row>
      </b-col>
      
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";

export default {
  components: {
    
  },
  data() {
    return {
      subject: {},
      subjects: [],
      stats: {},
    };
  },
  methods: {
    ...mapActions({
      getSubjects: "appData/getSubjectDropDown",
      getLectures: "appData/getLectures",
      getStudentStats: "appData/getStudentStats",
    }),

    async getDashboardData(){
      try {
        const res = await this.getStudentStats();
        this.stats = res.data;
      } catch (error) {
        this.displayError(error);
      }
    }
  },
  async mounted() {
    const res = await this.getSubjects();
    this.subjects = res.data;

    await this.getDashboardData();

  },
  
};
</script>
  
<style>

</style>
  
  
